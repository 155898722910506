
import {Options, Vue} from 'vue-class-component';
import http from "@/api/http";
@Options({
  data(){
    return {
      info:{}
    }
  },
  mounted() {

  },
  methods:{
    get(){
      http.get("/portal/list/info",{params:{id:this.$route.params.id}}).then(da=>{
        if(da.code == 1){
          this.info = da.data
        }
      })
    }
  },
  watch:{
    $route:{
      handler(){
        this.get()
      },
      immediate:true
    }
  }
})

export default class info extends Vue {

}
